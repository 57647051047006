<!-- <main  class="clearfix homepage"  >
	<div class="left-block">
		<button class="close-menu">
			<i class="fa fa-times"></i>
		</button>
		<div class="left-block-body">
			<nav>
				<div class="nav-logo">
					<a  routerLink="/home">
						<img src="assets/images/logo-icon.png" class="logo">
						<img src="assets/images/logo-icon2.png" class="logo-icon">
					</a>
				</div>
			</nav>
		</div>
	</div>
    <div class="right-block">
		<div class="Navoverlay"></div>
			<div class="right-block-body">
				<div  class="top-nav">
					<div class="nav-logo">
						<a routerLink="/home">
							<img src="assets/images/logo-icon.png" class="logo">
							<img src="assets/images/logo-icon2.png" class="logo-icon">
						</a>
					</div>
					
					
					<div class="nav-item clearfix">
						<button class="toggle-btn"><i class="fa fa-bars"></i></button>
						<div class="row">
							<div class="col-md-6 col-sm-6 col-xs-6">
								<div class="left-item">
									<ul>
										<li  >
											<a  >
												<i class="fas fa-images"></i>
												<span>Home</span>
											</a>
										</li>
										
										<li>
											<a   routerLink="/report">
												<i class="fa fa-file" aria-hidden="true"></i>
												<span>Dashboards</span>
											</a>
										</li>
										<li >
											<a *ngIf="User_type=='1'" routerLink="/users" >
												<i class="fas fa-th"></i>
												<span>More</span>
											</a>
											<a *ngIf="User_type!='1'" >
												<i class="fas fa-th"></i>
												<span>More</span>
											</a>
										</li>
									</ul>
								</div>
							</div>

							<div class="col-md-6 col-sm-6 col-xs-6 text-right">
								<div class="right-item">
									<ul >
										<li *ngIf="locationEmpty == 'false'">
									
										<div (click)="gotoaddmaximo()" class="notification-card">
											<a *ngIf="MaximoName" class="noti-btn">
												<span>{{MaximoName}}</span> 
											</a>
											<a *ngIf="OrgName" class="noti-btn">
												<i class="fas fa-angle-right"></i>
												<span>{{OrgName}}</span> 
											</a>
											<a *ngIf="SiteName" class="noti-btn">
												<i class="fas fa-angle-right"></i>
												<span>{{SiteName}}</span> 
											</a>
										</div>
									</li> 
								
									<li>
										<div>
										<div class=""  >
											 <a>
											 <span class="news" ><i class="fa fa-bell"  (click)="openNews(true)"></i></span> </a>
											 </div>
											 <div *ngIf="showNews == true" class="news-body">
												<app-news></app-news>
												<div (click)="openNews(false)" class="overlay-pop"></div>
											</div>
										</div>
									</li>
									<li>
										<div class="" routerLink="/help">
											 <a>
											 <span class="help"><i class="far fa-question-circle"></i></span> </a>
											 </div>
									</li>
									<li>
										<div class="user-profile">
											<div class="user-prof-inner" (click)="sidebarpopup=1">
												<span><i class="fas fa-bars"></i></span>
												<a>
												<img *ngIf="!userdetils?.image" src="assets/images/user-placeholder.png" alt="profile" class="img-responsive">

													<img *ngIf="userdetils?.image" src="{{userdetils?.image}}" alt="profile" class="img-responsive">
												</a>
											</div>
											<div *ngIf="sidebarpopup==1" class="noty-body">
												<ul>
													<li (click)="sidebarpopup=0" class="logout-btn">
														<div  class="img-name clearfix" routerLink="/editprofile">
															<div class="txt">
																<h2>Edit Profile</h2>
															</div>
														</div>
													</li> 
													<li (click)="sidebarpopup=0" class="logout-btn">
														
														<div  class="img-name clearfix" (click)="gotologout()">
															<div class="txt">
																<h2>Logout</h2>
															</div>
														</div>
													</li> 
												</ul>
												<div (click)="sidebarpopup=0" class="overlay-pop"></div>
											</div>
											
										</div>
									</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="main-body">
					<div class="inner-body"> -->

                        <div class="table-list">
                            <div class="table-head">
                                <div class="row">
                                <div class="th-left "><h5>Maven News</h5></div></div>

                            </div>
                                <div class="card-deck">

                                    <ng-container *ngFor="let data of newsData;let S = index" >
                                    <div class="card">
                                      <!-- <img class="card-img-top" src="..\assets\images\graph-big.jpg" alt="Card image cap"> -->
                                     
									  <div class="card-body">
                                        <h6 class="card-title"> <i  class="fa fa-newspaper"></i> &nbsp;{{data?.title}}</h6>
                                        <p class="card-text">{{data?.desc}}</p>
                                        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                                      </div>
                                    </div>
                                    </ng-container>

                                    </div>
                                
                            </div>

                    <!-- </div>
                </div>
            </div>
        </div>
        
</main> -->