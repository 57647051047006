import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

var redirectTourl ='login';
if(localStorage.getItem('isloginuser') == "1")
{
     var redirectTourl ='home';                
}
else
{
     var  redirectTourl ='login'; 
}

const routes: Routes = [
      {
            path: 'report',
            loadChildren: () => import('./report/report.module').then( m => m.ReportModule)
      },
      {
            path: '',
            redirectTo: redirectTourl,
            pathMatch: 'full'
      },
      {
            path: 'login',
            loadChildren: () => import('./login/login.module').then( m => m.LoginModule)
      }, 
      {
            path: 'reset-password',
            loadChildren: () => import('./reset-password/reset-password.module').then( m => m.ResetPasswordModule)
      }, 
      {
            path: 'change-password',
            loadChildren: () => import('./change-password/change-password.module').then( m => m.ChangePasswordModule)
      },
      {
            path: 'signup',
            loadChildren: () => import('./signup/signup.module').then( m => m.SignupModule)
      },
      {
            path: 'home',
            loadChildren: () => import('./home/home.module').then( m => m.HomeModule)
      },
      {
            path: 'home2',
            loadChildren: () => import('./Home2/home2.module').then( m => m.Home2Module)
      },
      {
            path: 'planning',
            loadChildren: () => import('./planning/planning.module').then( m => m.PlanningModule)
      },
      {
            path: 'work-management',
            loadChildren: () => import('./work-management/work-management.module').then( m => m.WorkmanagementModule)
      },
      {
            path: 'assets-management',
            loadChildren: () => import('./assets-management/assets-management.module').then( m => m.AssetsmanagementModule)
      },
      {
            path: 'clients',
            loadChildren: () => import('./clients/clients.module').then( m => m.ClientsModule)
      },
      {
            path: 'changepriority',
            loadChildren: () => import('./changepriority/changepriority.module').then( m => m.ChangepriorityModule)
      },
      {
            path: 'variable-configuration',
            loadChildren: () => import('./variable-config/variable-config.module').then( m => m.VariableConfigModule)
      },
      {
            path: 'add-new-user',
            loadChildren: () => import('./add-new-user/add-new-user.module').then( m => m.AddnewuserModule)
      },
      {
            path: 'add-new-client',
            loadChildren: () => import('./add-new-client/add-new-client.module').then( m => m.AddnewclientModule)
      },
      {
            path: 'users',
            loadChildren: () => import('./users/users.module').then( m => m.UsersModule)
      },
      {
            path: 'editprofile',
            loadChildren: () => import('./editprofile/editprofile.module').then( m => m.EditprofileModule)
      },
      {
            path: 'edituser',
            loadChildren: () => import('./edituser/edituser.module').then( m => m.EdituserModule)
      },
      {
            path: 'report-bug',
            loadChildren: () => import('./report-bug/report-bug.module').then( m => m.ReportBugModule)
      },
      {
            path: 'ask-maven-expert',
            loadChildren: () => import('./ask-expert/ask-expert.module').then( m => m.AskExpertModule)
      },
      {
            path: 'help',
            loadChildren: () => import('./help/help.module').then( m => m.HelpModule)
      },
      {
            path: 'news',
            loadChildren: () => import('./news/news.module').then( m => m.NewsModule)
      }/*,
      {
            path: 'assignmaximo',
            loadChildren: () => import('./assignmaximo/assignmaximo.module').then( m => m.AssignmaximoModule)
      }*/
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
