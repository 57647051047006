<main  class="clearfix"  >
	<div class="left-block">
		<button class="close-menu">
			<i class="fa fa-times"></i>
		</button>
		<div class="left-block-body">
			<nav>
				<div class="nav-logo">
					<a  routerLink="/home">
						<img src="assets/images/logo-icon.png" class="logo">
						<img src="assets/images/logo-icon2.png" class="logo-icon">
					</a>
				</div>

				<div  class="navlink">
						<ul id="accordion">
							<li>
								<a routerLink="/users">
									<span>Users</span>
									<i class="fas fa-chevron-right"></i>
								</a>
								
							</li>
							<li class="ciw-head"  >
								<a class="active sc-top" >
									<span>Settings</span>
									<i class="fas fa-chevron-right right clearclass"></i>
									<i class="fas fa-chevron-down down " ></i>
								</a>

								<ul  class="sc-bottom sc-bottom-report showthisbox" id="addclass">
									<li>
										<a routerLink="/changepriority">
											<span>Priority</span>
											<i class="fas fa-chevron-right"></i>
										</a>
									</li>
									<li>
										<a routerLink="/variable-configuration"  class="active">
											<span>Variable Configuration</span>
											<i class="fas fa-chevron-right"></i>
										</a>
									</li>
									</ul>
							</li>

							
							<li routerLink="/report-bug"  >
								<a class="sc-top" >
									<span>Contact Us</span>
									<i class="fas fa-chevron-right right "></i>
									<!-- <i class="fas fa-chevron-down down clearclass" ></i> -->
								</a>

								<!-- <ul  class="sc-bottom sc-bottom-report" id="addclass">
									<li>
										<a routerLink="/report-bug" >
											<span>Report a Bug</span>
											<i class="fas fa-chevron-right"></i>
										</a>
									</li>
									<li>
										<a routerLink="/ask-maven-expert" >
											<span>Ask a Maven Expert</span>
											<i class="fas fa-chevron-right"></i>
										</a>
									</li>
									</ul> -->
							</li>
						</ul>
					</div>
			</nav>
		</div>
	</div>
	<div class="right-block">
		<div class="Navoverlay"></div>
		<div class="right-block-body">
			<div class="top-nav">
				<div class="nav-logo">
					<a routerLink="/home">
						<img src="assets/images/logo-icon.png" class="logo">
						<img src="assets/images/logo-icon2.png" class="logo-icon">
					</a>
				</div>
				
				
				<div class="nav-item clearfix">
					<button class="toggle-btn"><i class="fa fa-bars"></i></button>
					<div class="row">
						<div class="col-md-7 col-sm-7 col-xs-7">
							<div class="left-item">
								<ul>
									<li><button class="toggle-side"><i class="fa fa-bars"></i></button></li>
									<li  >
										<a  routerLink="/home" >
											<i class="fas fa-images"></i>
											<span>Home</span>
										</a>
									</li>
									
									<li>
										<a   routerLink="/report">
											<i class="fa fa-file" aria-hidden="true"></i>
											<span>Dashboards</span>
										</a>
									</li>
									<!-- <li>
										<a   routerLink="/news">
											<i class="fa fa-rss" aria-hidden="true"></i>
											<span>News</span>
										</a>
									</li> -->
									<li  class="active" >
										<a   routerLink="/users" >
											<i class="fas fa-th"></i>
											<span>More</span>
										</a>
										
									</li>
								</ul>
							</div>
						</div>

						<div class="col-md-5 col-sm-5 col-xs-5 text-right">
							<div class="right-item">
								<ul >
								
									<li>
										<div>
										<div class=""  >
											 <a>
											 <span class="news" ><i class="fa fa-bell"  (click)="openNews(true)"></i></span> </a>
											 </div>
											 <div *ngIf="showNews == true" class="news-body">
												<app-news></app-news>
												<div (click)="openNews(false)" class="overlay-pop"></div>
											</div>
										</div>
									</li>
									<li>
										<div class="" routerLink="/help">
											 <a>
											 <span class="help"><i class="far fa-question-circle"></i></span> </a>
											 </div>
									</li>
							
								<li>
									<div class="user-profile">
										<div class="user-prof-inner" (click)="sidebarpopup=1">
											<span><i class="fas fa-bars"></i></span>
											<a>
											<img *ngIf="!userdetils?.image" src="assets/images/user-placeholder.png" alt="profile" class="img-responsive">

												<img *ngIf="userdetils?.image" src="{{userdetils?.image}}" alt="profile" class="img-responsive">
											</a>
										</div>
										<div *ngIf="sidebarpopup==1" class="noty-body">
											<ul>
												<li (click)="sidebarpopup=0" class="logout-btn">
													<div  class="img-name clearfix" routerLink="/editprofile">
														<div class="txt">
															<h2>Edit Profile</h2>
														</div>
													</div>
												</li> 
												<li (click)="sidebarpopup=0" class="logout-btn">
													
													<div  class="img-name clearfix" (click)="gotologout()">
														<div class="txt">
															<h2>Logout</h2>
														</div>
													</div>
												</li> 
											</ul>
											<div (click)="sidebarpopup=0" class="overlay-pop"></div>
										</div>
										
									</div>
								</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="main-body">
				<div ID="MyTable1" class="inner-body ">
					<div class="">
						
						<!-- <p>We'll use this to prioritize our recommendations so that it fits your needs.</p> -->
						<div *ngIf="errormsg==1"class="error-mess">
							<p>{{errrormessage}}</p>
						</div>
                        
					<div *ngIf="sucessmsg==1"class="success-mess">
						<p>{{errrormessage}}</p>
					</div>
						<!-- <ul cdkDropList (cdkDropListDropped)="drop($event)"  class="example-list" >
							  <li class="example-box" *ngFor="let movie of displayedColumns" cdkDrag><span>{{movie?.name}}</span></li>
						</ul> -->
						<h4>Select Location</h4>	
						<div class="add-maixmo-sel">
							<table>
								<tbody>
									<tr>
										<td width=30%>
										<div class="psingle-fld">
											<select [ngModel]="Maximo_ID"(ngModelChange)="Addmaximotop($event,'1')">
													<option  value="" >Choose Location</option>
												  <option *ngFor="let ml of MaximoList" value="{{ml?.id}}" >{{ml?.name | titlecase}}</option>
											  </select>
										  </div>
									  </td>
									  <td width=30% >
										  <div class="psingle-fld marginse">
											  <select [ngModel]="Org_id" 
											  (ngModelChange)="AddOrgnigationtop($event,'1')">
												  <option  value="" >Choose Organization</option>
												  <option *ngFor="let ol of Org_list" value="{{ol?.id}}" >{{ol?.name | titlecase}}</option>
											  </select>
										  </div>
									  </td>
									  <td width=30% >
										  <div class="psingle-fld">
											  <select [ngModel]="Site_id" 
											  (ngModelChange)="Addsitechange($event)">
												  <option  value="" >Choose Site</option>
												  <option *ngFor="let sl of Site_list" value="{{sl?.id}}" >{{sl?.name | titlecase}}</option>
											  </select>
										  </div>
									  </td>
									  <td width=10% ><div (click)="loadVariableConfigData()" class="search-fld">
										  <button >Apply</button>
									  </div></td>
									</tr>
								</tbody>
							</table>		
					</div>	


					<h4>Variable Configuration</h4>
					<div class="add-maixmo-sel">
						
						<table *ngIf="variableData?.length > 0"><th width=25%>
							<label>Variable Name :</label>
						</th><th width=25%>
							<label>Variable Value :</label>
						</th><th width=45%>
							<label>Description :</label>
						</th><th width=5%>
						</th>
							<tbody>
								<ng-container *ngFor="let item of variableData; let j=index" >
                            	   <tr>
										<td>
											<div class="psingle-fld">
											  <input [(ngModel)]="item.name"  disabled>
											</div>
										</td>
										<td >
											<div class="psingle-fld marginse">
												<input type="number" [(ngModel)]="item.value" >
											  
											</div>
										</td>
										<td class="description">
											<div class="psingle-fld">
												<input [(ngModel)]="item.description" disabled>
											</div>
										</td>
											<td>
											<button (click)="updateVariable(item)">Update</button>
										</td>
									
									</tr>
								</ng-container>
							</tbody>
						</table>

                       
                </div>
                <!-- <div class="ac-btn">
                <button (click)="updateVariable(variableData)">Update</button>
                </div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</main>
