import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

 
@Injectable({ providedIn: 'root' })
export class ChangeLocService {
    private Location = new Subject<any>();
 
    chnagetolocation(lo:string){
  	 	console.log('call'+lo);
      	this.Location.next({loc:lo});
    }
    
    getLocation(): Observable<any>{
      	return this.Location.asObservable();
    }
 

 
}