import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoaderService } from '../loader.service';
import { UserdataService } from '../Userdata.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  sidebarpopup:any=0;
  userdetils = {
      "firstname": "",
      "lastname": "",
      "username": "",
      "user_id":'',
      "image":""
  };
  Maximo_ID:any='';
 MaximoName:any='';
 Org_id:any='';
 OrgName:any='';
 Site_id:any='';
 SiteName:any='';

 firstcall:any=0;
 token:any;
 totaldata:any=[];
 User_type:any=0;
 subscription: Subscription;
 locationEmpty:string='false';

 newsData:any = [{"title": "News title", 
"desc" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."},
{"title": "News title", 
"desc" : "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "},
{"title": "News title", 
"desc" : " Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."},
{"title": "News title", 
"desc" : "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."}
];

  constructor(private loaderService: LoaderService,public maximoService: UserdataService,public route: Router) { 
      // this.loadpagefunfr();
      this.token=localStorage.getItem('usertoken');
      if(this.firstcall==0)
      {    
            this.totaldata=[];       
            this.firstcall=1;        
            this.loadmaximodata('1');
      }
      this.User_type=localStorage.getItem('user_type');
      this.userdetails();
      this.subscription = this.maximoService.getloader().subscribe(product=> {
           if(localStorage.getItem('Pagechange')=='1')
           {
                  localStorage.setItem('Pagechange','0');
                  this.sendpagecall();
           }
            else 
           {
                   this.loadmaximodata('2');
           }
      });
      if (localStorage.getItem('locationEmpty') == 'true'){
            this.locationEmpty = 'true';
      } else {
            this.locationEmpty = 'false';
      }
  }

  ngOnInit(): void {
  }

  loadmaximodata(ty:any){
    $("#mainpagecs").removeClass("notshow");
      console.log('call home first');
      let maximodata = JSON.parse(localStorage.getItem('Maximo_data') || '{}');
     
      if(maximodata)
      {
            this.Maximo_ID=maximodata.mid;
            this.MaximoName=maximodata.mname;
            this.Org_id=maximodata.oid;
            this.OrgName=maximodata.oname;
            this.Site_id=maximodata.sid;
            this.SiteName=maximodata.sname;
            if(ty == '2')
            {
                  // this.loaddaatdashbrd();
            } 
            
      }

      // this.flag = 0;
      if(ty != '2') {
            // this.loaddaatdashbrd();
      }
}


sendpagecall()
{
      this.route.navigate(["/users"]);
}

userdetails()
      {
            let userdata = JSON.parse(localStorage.getItem('localuserdata') || '{}');
            
            if(userdata)
            {
                  this.userdetils.user_id=userdata.id;
                  this.userdetils.image = userdata.profileImage;
            }
      }
  gotoaddmaximo()
  {
       this.loaderService.chnagetolloader('1');
       //this.gotoWMdatafun(this.Maximo_ID);
  }

  
  gotologout()
  {
      localStorage.removeItem('isloginuser');
      localStorage.removeItem('usertoken');
      localStorage.removeItem('userdata');
      localStorage.removeItem('locationEmpty');
      this.route.navigate(["/login"]);
  }
}

