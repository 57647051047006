<div class="content hideiconclass"><span>maven app is running!</span></div>
<router-outlet>
	<div *ngIf="showloader==true" id="loader-wrapper">
		<div id="loader">
			<div class="svg-wrapper">
				<img src="assets/images/loader1.gif">
			</div>
		</div>
	</div>
	<div *ngIf="changemaximopoup==1 && locationEmpty == 'false'" class="popup-wrap">
		<div class="overlay"></div>
		<div class="popup-inner-smax">
			<div class="pop-main">
				<div class="pop-body">
					<div class="ser-maixmo-sel">

						<ul>
							<li>
								<div class="single-fld">
									<select [ngModel]="Maximo_ID" 
									(ngModelChange)="Changemaximotop($event,'1')">
										<option  value="" >Choose Location</option>
										<option *ngFor="let ml of MaximoList"  value="{{ml?.id}}" >{{ml?.name | titlecase}}</option>
									</select>
								</div>
							</li>
							<li >
								<div class="single-fld">
									<select [ngModel]="Org_id" 
									(ngModelChange)="ChangeOrgnigationtop($event,'1')">
										<option  value="" >Choose Organization</option>
										<option *ngFor="let ol of Org_list" value="{{ol?.id}}" >{{ol?.name | titlecase}}</option>
									</select>
								</div>
							</li>
							<li >
								<div class="single-fld">
									<select [ngModel]="Site_id" 
									(ngModelChange)="ChangeSitefun($event)">
										<option  value="" >Choose Site</option>
										<option *ngFor="let sl of Site_list" value="{{sl?.id}}" >{{sl?.name | titlecase}}</option>
									</select>
								</div>
								<div class="single-fld">
									<button (click)="Addallmaximoadorg()" >Apply</button>
									<div *ngIf="closebtnpop==0"class="croess" (click)="closeaddmaximo()" >
										<i class="fas fa-times"></i>
									</div>
								</div>
							</li>

						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</router-outlet>