import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { HeaderComponent } from './header/header.component';
import { DatePipe } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { VariableConfigComponent } from './variable-config/variable-config.component';
import { ReportBugComponent } from './report-bug/report-bug.component';
import { AskExpertComponent } from './ask-expert/ask-expert.component';
import { HelpComponent } from './help/help.component';
import { NewsModule } from './news/news.module';

@NgModule({
     declarations: [
          AppComponent,
          HeaderComponent,
          VariableConfigComponent,
          ReportBugComponent,
          AskExpertComponent,
          HelpComponent,
     ],
     imports: [
          BrowserModule,
          AppRoutingModule,
          HttpClientModule,
          FormsModule,
          NgMultiSelectDropDownModule.forRoot(),
          ReactiveFormsModule,
          DragDropModule,
          MDBBootstrapModule.forRoot(),
          NgxPaginationModule,
          NewsModule
     ],
     exports:[],
     providers: [ DatePipe,],
     bootstrap: [AppComponent]
})
export class AppModule { }
