import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoaderService } from '../loader.service';
import { UserdataService } from '../Userdata.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  sidebarpopup:any=0;
  userdetils = {
      "firstname": "",
      "lastname": "",
      "username": "",
      "user_id":'',
      "image":""
  };
  Maximo_ID:any='';
 MaximoName:any='';
 Org_id:any='';
 OrgName:any='';
 Site_id:any='';
 SiteName:any='';

 firstcall:any=0;
 token:any;
 totaldata:any=[];
 User_type:any=0;
 subscription: Subscription;
 locationEmpty:string='false';
 helpData:any = [{"qus": "How can I contact to Maven Experts?", 
"ans" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."},
{"qus": "How can I see the Score?", 
"ans" : "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."},
{"qus": "How can I change m priority list?", 
"ans" : "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."},
{"qus": "How can I contact to Maven Experts?", 
"ans" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}
];
showNews: boolean = false;
  constructor(private loaderService: LoaderService,public maximoService: UserdataService,public route: Router) { 
      // this.loadpagefunfr();
      this.token=localStorage.getItem('usertoken');
      if(this.firstcall==0)
      {    
            this.totaldata=[];       
            this.firstcall=1;        
            this.loadmaximodata('1');
      }
      this.User_type=localStorage.getItem('user_type');
      this.userdetails();
      this.subscription = this.maximoService.getloader().subscribe(product=> {
           if(localStorage.getItem('Pagechange')=='1')
           {
                  localStorage.setItem('Pagechange','0');
                  this.sendpagecall();
           }
            else 
           {
                   this.loadmaximodata('2');
           }
      });
      if (localStorage.getItem('locationEmpty') == 'true'){
            this.locationEmpty = 'true';
      } else {
            this.locationEmpty = 'false';
      }
      
    $("#addclass0").slideToggle();
    $("#hideiconclass0").removeClass('hideiconclass');
    $("#showiconclass0").addClass('hideiconclass'); 
  }

  ngOnInit(): void {
    // $('#showiconclass0').click();
    this.gotoaddcall(0);

  }

  loadmaximodata(ty:any){
    $("#mainpagecs").removeClass("notshow");
      console.log('call home first');
      let maximodata = JSON.parse(localStorage.getItem('Maximo_data') || '{}');
     
      if(maximodata)
      {
            this.Maximo_ID=maximodata.mid;
            this.MaximoName=maximodata.mname;
            this.Org_id=maximodata.oid;
            this.OrgName=maximodata.oname;
            this.Site_id=maximodata.sid;
            this.SiteName=maximodata.sname;
            if(ty == '2')
            {
                  // this.loaddaatdashbrd();
            } 
            
      }

      // this.flag = 0;
      if(ty != '2') {
            // this.loaddaatdashbrd();
      }
}


sendpagecall()
{
      this.route.navigate(["/users"]);
}

userdetails()
      {
            let userdata = JSON.parse(localStorage.getItem('localuserdata') || '{}');
            
            if(userdata)
            {
                  this.userdetils.user_id=userdata.id;
                  this.userdetils.image = userdata.profileImage;
            }
            
            $('#showiconclass0').click();
      }
  gotoaddmaximo()
  {
       this.loaderService.chnagetolloader('1');
       //this.gotoWMdatafun(this.Maximo_ID);
  }

  
  gotoaddcall(id:any)
  {     
    $(".clearclass").removeClass('showthisbox');
    $(".clearshow").removeClass('hideiconclass');
    $(".clearhide").addClass('hideiconclass');
     $(".clearclass").slideUp();
    $("#addclass"+id).addClass('showthisbox'); 
    $("#addclass"+id).slideDown();
    
    $("#hideiconclass"+id).removeClass('hideiconclass');
    $("#showiconclass"+id).addClass('hideiconclass');   

  }
  
  gotoremovecall(id:any)
  {     
        $(".clearclass").slideUp();
        $(".clearshow").removeClass('hideiconclass');
        $(".clearhide").addClass('hideiconclass');
        // $(".clearclassD").slideUp();
        // $(".clearshowD").removeClass('hideiconclass');
        // $(".clearhideD").addClass('hideiconclass');

  }
  gotologout()
  {
      localStorage.removeItem('isloginuser');
      localStorage.removeItem('usertoken');
      localStorage.removeItem('userdata');
      localStorage.removeItem('locationEmpty');
      this.route.navigate(["/login"]);
  }
  openNews(state: boolean) {
      console.log(state);
      this.showNews = state;
    }
}
