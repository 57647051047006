<main  class="clearfix"  >
	<div class="left-block">
		<button class="close-menu">
			<i class="fa fa-times"></i>
		</button>
		<div class="left-block-body">
			<nav>
				<div class="nav-logo">
					<a  routerLink="/home">
						<img src="assets/images/logo-icon.png" class="logo">
						<img src="assets/images/logo-icon2.png" class="logo-icon">
					</a>
				</div>

				<div  class="navlink">
						<ul id="accordion">
							<li>
								<a routerLink="/users">
									<span>Users</span>
									<i class="fas fa-chevron-right"></i>
								</a>
								
							</li>
							<li class="ciw-head"  >
								<a class=" sc-top" >
									<span>Settings</span>
									<i class="fas fa-chevron-right right clearclass"></i>
									<i class="fas fa-chevron-down down " ></i>
								</a>

								<ul  class="sc-bottom sc-bottom-report " id="addclass">
									<li>
										<a routerLink="/changepriority">
											<span>Priority</span>
											<i class="fas fa-chevron-right"></i>
										</a>
									</li>
									<li>
										<a routerLink="/variable-configuration" >
											<span>Variable Configuration</span>
											<i class="fas fa-chevron-right"></i>
										</a>
									</li>
									</ul>
							</li>

							
							<li routerLink="/report-bug" >
								<a class=" active sc-top" >
									<span>Contact Us</span>
									<i class="fas fa-chevron-right right "></i>
									<!-- <i class="fas fa-chevron-down down clearclass" ></i> -->
								</a>

								<!-- <ul  class="sc-bottom sc-bottom-report showthisbox" id="addclass">
									<li>
										<a routerLink="/report-bug" >
											<span>Report a Bug</span>
											<i class="fas fa-chevron-right"></i>
										</a>
									</li>
									<li>
										<a routerLink="/ask-maven-expert" class="active">
											<span>Ask a Maven Expert</span>
											<i class="fas fa-chevron-right"></i>
										</a>
									</li>
									</ul> -->
							</li>
						</ul>
					</div>
			</nav>
		</div>
	</div>
	<div class="right-block">
		<div class="Navoverlay"></div>
		<div class="right-block-body">
			<div class="top-nav">
				<div class="nav-logo">
					<a routerLink="/home">
						<img src="assets/images/logo-icon.png" class="logo">
						<img src="assets/images/logo-icon2.png" class="logo-icon">
					</a>
				</div>
				
				
				<div class="nav-item clearfix">
					<button class="toggle-btn"><i class="fa fa-bars"></i></button>
					<div class="row">
						<div class="col-md-7 col-sm-7 col-xs-7">
							<div class="left-item">
								<ul>
									<li><button class="toggle-side"><i class="fa fa-bars"></i></button></li>
									<li  >
										<a  routerLink="/home" >
											<i class="fas fa-images"></i>
											<span>Home</span>
										</a>
									</li>
									
									<li>
										<a   routerLink="/report">
											<i class="fa fa-file" aria-hidden="true"></i>
											<span>Dashboards</span>
										</a>
									</li>
									<!-- <li>
										<a   routerLink="/news">
											<i class="fa fa-rss" aria-hidden="true"></i>
											<span>News</span>
										</a>
									</li> -->
									<li  class="active" >
										<a   routerLink="/users" >
											<i class="fas fa-th"></i>
											<span>More</span>
										</a>
										
									</li>
								</ul>
							</div>
						</div>

						<div class="col-md-5 col-sm-5 col-xs-5 text-right">
                            <i class="fas fa-circle-question"></i>
							<div class="right-item">
								<ul >
									<li>
										<div>
										<div class=""  >
											 <a>
											 <span class="news" ><i class="fa fa-bell"  (click)="openNews(true)"></i></span> </a>
											 </div>
											 <div *ngIf="showNews == true" class="news-body">
												<app-news></app-news>
												<div (click)="openNews(false)" class="overlay-pop"></div>
											</div>
										</div>
									</li>
									<li>
										<div class="" routerLink="/help">
											 <a>
											 <span class="help"><i class="far fa-question-circle"></i></span> </a>
											 </div>
									</li>
							
								<li>
									<div class="user-profile">
										<div class="user-prof-inner" (click)="sidebarpopup=1">
											<span><i class="fas fa-bars"></i></span>
											<a>
											<img *ngIf="!userdetils?.image" src="assets/images/user-placeholder.png" alt="profile" class="img-responsive">

												<img *ngIf="userdetils?.image" src="{{userdetils?.image}}" alt="profile" class="img-responsive">
											</a>
										</div>
										<div *ngIf="sidebarpopup==1" class="noty-body">
											<ul>
												<li (click)="sidebarpopup=0" class="logout-btn">
													<div  class="img-name clearfix" routerLink="/editprofile">
														<div class="txt">
															<h2>Edit Profile</h2>
														</div>
													</div>
												</li> 
												<li (click)="sidebarpopup=0" class="logout-btn">
													
													<div  class="img-name clearfix" (click)="gotologout()">
														<div class="txt">
															<h2>Logout</h2>
														</div>
													</div>
												</li> 
											</ul>
											<div (click)="sidebarpopup=0" class="overlay-pop"></div>
										</div>
										
									</div>
								</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="main-body">
				<div class="inner-body">
                    <div class="table-list">
                        <div class="table-head">
                            <div class="th-left"><h3>Contact Us</h3>
						</div>
						<div class="sub-head">
							<strong>
								How Can We Help?
							</strong>
							<span>Please select an option below related to your query.</span>
						</div>
						<div class="add-client">
                            <div class="">
								<div class="form-check form-check-inline mt-2 ">
									<input class="form-check-input" type="radio" name="status" id="active" value="1"
									(change)="goToRouteChange()">
									<label class="form-check-label" for="active">Report a bug</label>
								  </div>
								  <div class="form-check form-check-inline">
									<input class="form-check-input" type="radio" name="status" id="inactive" value="0"
									checked>
									<label class="form-check-label" for="inactive">Ask a Maven Expert</label>
								  </div>
							</div>
							</div>
                        </div>
                        <div class="add-client">
                            <div class="ac-body">
                                <div *ngIf="errormsg==1"class="error-mess">
                                    <p>{{errrormessage}}</p>
                                </div>
                                        
                            <div *ngIf="sucessmsg==1"class="success-mess">
                                <p>{{errrormessage}}</p>
                            </div>
                                <ul>
                                    
                                    <li>
                                        <div class="ac-single">
                                            <div class="acs-head">Name *</div>
                                            <div class="acs-input">
                                                <input type="text" [(ngModel)]="askExpertDetails.name" (ngModelChange)="removeerror()">
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="ac-single">
                                            <div class="acs-head">Email Id *</div>
                                            <div class="acs-input">
                                                <input type="text" [(ngModel)]="askExpertDetails.email" (ngModelChange)="removeerror()">
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="ac-single">
                                            <div class="acs-head">Query *</div>
                                            <div class="acs-input">
                                                <textarea type="text" [(ngModel)]="askExpertDetails.query" (ngModelChange)="removeerror()"></textarea>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="select-image"> 
                                             <!-- <label> Upload image -->
                                                <!-- <i class="fa fa-upload" aria-hidden="true"></i> -->
                                                 <!-- <input id="file" type="file" class="form-control" multiple="" (change)="onFileChange($event)"> -->

                                             <!-- <img class="profile-pic" src="assets/images/user-placeholder.png"> -->
                                             <!-- <div class="change-pic">
                                                 <i class="fa fa-camera" aria-hidden="true"></i>
                                                 <p>Change picture</p>
                                               </div> -->
                                         <!-- </label> -->
                                         <!-- <p  class="acs-head">{{SendImage?.name}}</p> -->
                                        </div>
                                    </li>
                                    
                                    
                                    
                                </ul>
                                <div class="ac-btn">
                                    <!-- <button routerLink="/users" class="back-btn">Back</button> -->
                                    <button >Submit</button>
                                </div>
                            </div>								
                        </div>
                        
                    </div>
                </div>
            </div>
                    </div>
                </div>
</main>
