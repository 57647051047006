  import { Component, OnInit } from '@angular/core';
  import { Router } from '@angular/router';
  import { LoaderService } from '../loader.service';
  import { RestService } from '../services/rest.service';

@Component({
  selector: 'app-ask-expert',
  templateUrl: './ask-expert.component.html',
  styleUrls: ['./ask-expert.component.scss']
})
export class AskExpertComponent implements OnInit {
  errormsg:any=0;
  errrormessage:any='';
  sucessmsg:any=0;
  sidebarpopup:any=0;
  userdetils = {
      "firstname": "",
      "lastname": "",
      "username": "",
      "user_id":'',
      "image":""
  };
  
  askExpertDetails = {
    "name" : "",
    "email" : "",
    "query" : "",
  }
  token:any;
   firstcall:any=0;
   User_type:any=0;
   MaximoList:any=[];
   Maximo_ID:any='';
   MaximoName:any='';
   Org_list:any=[];
   Org_id:any='';
   OrgName:any='';
   Site_list:any=[];
   Site_id:any='';
   SiteName:any='';
 
      showNews: boolean = false;

  constructor(
    public route: Router,
    public rest: RestService,
    private loaderService: LoaderService
  ) {
    this.token=localStorage.getItem('usertoken');
            if(this.firstcall==0)
            {               
                  this.loadpagefuntowew();
            }
            this.User_type=localStorage.getItem('user_type');
            this.userdetails();

           
   }
   
  ngOnInit(): void {
    this.User_type=localStorage.getItem('user_type');
    $('.toggle-side').click(function() {
      // $("body").toggleClass("toggle-class");
      if($(".navlink").hasClass('sidebar-hide')) {
            $(".navlink").addClass('sidebar-show');
            $(".navlink").removeClass('sidebar-hide');
            $(".inner-body ").css("margin-left", "0px");
      } else {
            $(".navlink").addClass('sidebar-hide');
            $(".navlink").removeClass('sidebar-show');
            $(".inner-body ").css("margin-left", "-220px");
      }
});

$('.ciw-head').click(function(e) {
  e.stopPropagation();
  $(this).find('#addclass').slideToggle();
  if( $(this).find(".down").hasClass('clearclass')){
       $(this).find(".right").addClass('clearclass');
       $(this).find(".down").removeClass('clearclass');
       $(this).find(".down").addClass('showthisbox');
       $(this).find("#addclass").addClass('showthisbox');
  } else {
       $(this).find(".right").removeClass('clearclass');
       $(this).find(".down").addClass('clearclass');
       $(this).find(".down").removeClass('showthisbox');
       $(this).find("#addclass").removeClass('showthisbox');
  }
  });

   $(".toggle-btn, .close-menu, .Navoverlay,.navlink ul li a:not('.sc-top')").click(function() {
        $("body").toggleClass("toggle-class");
  });

  $('.sc-right > i, .scr-inner > i').click(function() {
        $(this).parents('.radio-type-check ul li').siblings('li').removeClass('open');
        $(this).parents('.radio-type-check ul li').toggleClass('open');
        $(this).parents('.radio-type-check ul li').siblings('li').find('.sc-bottom').slideUp();
        $(this).parents('.sc-top').next('.sc-bottom').slideToggle();
  });
  $('.minor-imp span').click(function() {
        $(this).parents('.radio-type-check ul li').toggleClass('open');
  });
  $('.toggle-pan').click(function() {
        $(this).parents('.report-top').toggleClass('close-right-pan');
  });
  $('.alert-pop').click(function() {
        $(this).parents('main').addClass('show-pop');
  });
  $('.pop-head .close-icon, .pop-foot .normal-btn button').click(function() {
        $(this).parents('main').removeClass('show-pop');
  });
  this.token=localStorage.getItem('usertoken');
  if(this.firstcall==0)
  {               
        this.loadpagefuntowew();
  }

  }


  userdetails()
  {
        if(localStorage.getItem('Setup_value')=='0')
        {
              console.log('change priopp aa gaya');
              this.route.navigate(["/home2"]);
        }
        let userdata = JSON.parse(localStorage.getItem('localuserdata') || '{}');
        console.log(userdata);
        if(userdata)
        {
              this.userdetils.user_id=userdata.id;
              this.userdetils.image = userdata.profileImage;
        }
  }
  loadpagefuntowew()
  {
        this.loadmaximodata();

        this.rest.Authodatagetservice("maximo",this.token).subscribe( res => {
         let data:any=res;
         console.log(data);
         // this.loadmaximoist();
        
         if(data.instances)
         {
             
               this.MaximoList=data.instances; 
         }
         else
         {
               this.MaximoList=[]; 
             
         }
   },error => {
         console.log(error);
         this.loaderService.chnagetolloader('false');
   });
       // this.loadpagefunmatyirix();
      /* this.subscription = this.maximoService.getloader().subscribe(product=> {
            this.loadmaximodata();
       });*/
       
  }

  loadmaximodata(){
    let maximodata = JSON.parse(localStorage.getItem('Maximo_data') || '{}');
    console.log(maximodata);
    if(maximodata)
    {
         this.Maximo_ID=maximodata.mid;
         this.MaximoName=maximodata.mname;
         this.Org_id=maximodata.oid;
         this.OrgName=maximodata.oname;
         this.Site_id=maximodata.sid;
         this.SiteName=maximodata.sname;
         this.Addmaximotop(this.Maximo_ID,'2'); 
    }
    
    // this.loadVariableConfigData();
//     this.Addmaximotop(this.Maximo_ID);
//     this.AddOrgnigationtop(this.Org_id);
}

Addmaximotop(ev:any, ct:any)
      {
            console.log(ev);
            if(ct==1)
            {
                  this.Org_id='';
                  this.OrgName='';
                  this.Site_id='';
            }
            this.Maximo_ID=ev;
            this.addmaximo();
            this.Site_list=[];
            this.Org_list=[];
           
            this.rest.Authodatagetservice("maximo/"+ev+"/organization",this.token).subscribe( res => {
                  let data:any= res;
                  console.log(data);
                  
                  if(data.organizations.length > 0)
                  {
                        this.Org_list=data.organizations;
                        if(ct==2)
                        {
                              this.AddOrgnigationtop(this.Org_id,2);
                        }
                  }
                  else
                  {
                        this.Org_list=[];  
                  }
            },error => {
                  this.loaderService.chnagetolloader('false');
                  
            }); 
      }
      
      addmaximo()
      {
            
            var maximo = this.MaximoList;
            for (let i = 0; i < maximo.length; i++) {
                  if(this.Maximo_ID==maximo[i].id)
                  {
                        this.MaximoName=maximo[i].name;
                  }
                  
            }
          
      }
      AddOrgnigationtop(eve:any,ct:any)
      {
            
            this.Org_id=eve;
            this.addOrgnigation();
            this.Site_list=[];
            console.log(eve);
            console.log(ct +"cttttttttttt");
            if(ct==1)
            {
                  this.Site_id='';
                  this.SiteName='';
            }
            
            this.rest.Authodatagetservice("maximo/"+this.Maximo_ID+"/organization/"+eve+"/site",this.token).subscribe( res => {
                  let data:any= res;
                  console.log(data);
                  
                  if(data.sites.length > 0)
                  {
                        this.Site_list=data.sites;
                        console.log(this.Site_id);
                  }
                  else
                  {
                        this.Site_list=[];  
                  }
            },error => {
                  this.loaderService.chnagetolloader('false');
                 
            }); 
      }
      addOrgnigation()
      {
            var org = this.Org_list;
            for (let i = 0; i < org.length; i++) {
                  if(this.Org_id==org[i].id)
                  {
                        this.OrgName=org[i].name;
                  }
                  
            }
            
      }
      Addsitechange(nt:any)
      {
            this.Site_id=nt;
            var org = this.Site_list;
            for (let i = 0; i < org.length; i++) {
                  if(this.Site_id==org[i].id)
                  {
                        this.SiteName=org[i].name;
                  }
                  
            }
      }

  
    removeerror()
    {
          this.errormsg=0;
          this.errrormessage="";
    }
   
    goToRouteChange(){
      this.route.navigate(['/report-bug']);
}

  gotologout()
  {
       localStorage.removeItem('isloginuser');
       localStorage.removeItem('usertoken');
       localStorage.removeItem('userdata');
       localStorage.removeItem('locationEmpty');
       this.route.navigate(["/login"]);
  }

  openNews(state: boolean) {
      console.log(state);
      this.showNews = state;
    }

}


