<main  class="clearfix homepage"  >
	<div class="left-block">
		<button class="close-menu">
			<i class="fa fa-times"></i>
		</button>
		<div class="left-block-body">
			<nav>
				<div class="nav-logo">
					<a  routerLink="/home">
						<img src="assets/images/logo-icon.png" class="logo">
						<img src="assets/images/logo-icon2.png" class="logo-icon">
					</a>
				</div>
			</nav>
		</div>
	</div>
    <div class="right-block">
		<div class="Navoverlay"></div>
			<div class="right-block-body">
				<div  class="top-nav">
					<div class="nav-logo">
						<a routerLink="/home">
							<img src="assets/images/logo-icon.png" class="logo">
							<img src="assets/images/logo-icon2.png" class="logo-icon">
						</a>
					</div>
					
					
					<div class="nav-item clearfix">
						<button class="toggle-btn"><i class="fa fa-bars"></i></button>
						<div class="row">
							<div class="col-md-6 col-sm-6 col-xs-6">
								<div class="left-item">
									<ul>
										<li >
											<a   routerLink="/home">
												<i class="fas fa-images"></i>
												<span>Home</span>
											</a>
										</li>
										
										<li>
											<a   routerLink="/report">
												<i class="fa fa-file" aria-hidden="true"></i>
												<span>Dashboards</span>
											</a>
										</li>
                                        <!-- <li>
											<a   routerLink="/news">
												<i class="fa fa-rss" aria-hidden="true"></i>
												<span>News</span>
											</a>
										</li> -->
										<li >
											<a *ngIf="User_type=='1'" routerLink="/users" >
												<i class="fas fa-th"></i>
												<span>More</span>
											</a>
											<a *ngIf="User_type!='1'" >
												<i class="fas fa-th"></i>
												<span>More</span>
											</a>
										</li>
									</ul>
								</div>
							</div>

							<div class="col-md-6 col-sm-6 col-xs-6 text-right">
								<div class="right-item">
									<ul >
										<li *ngIf="locationEmpty == 'false'">
									
										<div (click)="gotoaddmaximo()" class="notification-card">
											<a *ngIf="MaximoName" class="noti-btn">
												<span>{{MaximoName}}</span> 
											</a>
											<a *ngIf="OrgName" class="noti-btn">
												<i class="fas fa-angle-right"></i>
												<span>{{OrgName}}</span> 
											</a>
											<a *ngIf="SiteName" class="noti-btn">
												<i class="fas fa-angle-right"></i>
												<span>{{SiteName}}</span> 
											</a>
										</div>
									</li> 
								
									<li>
										<div>
										<div class=""  >
											 <a>
											 <span class="news" ><i class="fa fa-bell"  (click)="openNews(true)"></i></span> </a>
											 </div>
											 <div *ngIf="showNews == true" class="news-body">
												<app-news></app-news>
												<div (click)="openNews(false)" class="overlay-pop"></div>
											</div>
										</div>
									</li>
									<li>
										<div class="" routerLink="/help">
											 <a>
											 <span class="help"><i class="far fa-question-circle"></i></span> </a>
											 </div>
									</li>
									<li>
										<div class="user-profile">
											<div class="user-prof-inner" (click)="sidebarpopup=1">
												<span><i class="fas fa-bars"></i></span>
												<a>
												<img *ngIf="!userdetils?.image" src="assets/images/user-placeholder.png" alt="profile" class="img-responsive">

													<img *ngIf="userdetils?.image" src="{{userdetils?.image}}" alt="profile" class="img-responsive">
												</a>
											</div>
											<div *ngIf="sidebarpopup==1" class="noty-body">
												<ul>
													<li (click)="sidebarpopup=0" class="logout-btn">
														<div  class="img-name clearfix" routerLink="/editprofile">
															<div class="txt">
																<h2>Edit Profile</h2>
															</div>
														</div>
													</li> 
													<li (click)="sidebarpopup=0" class="logout-btn">
														
														<div  class="img-name clearfix" (click)="gotologout()">
															<div class="txt">
																<h2>Logout</h2>
															</div>
														</div>
													</li> 
												</ul>
												<div (click)="sidebarpopup=0" class="overlay-pop"></div>
											</div>
											
										</div>
									</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="main-body">
					<div class="inner-body">

                        <div class="table-list">
                            <div class="table-head">
                                <div class="th-left"><h3>Help Centre</h3></div>
                                
                            </div>
                            </div>
                        <div class="checklist-ind-wrap">
							<div *ngFor="let dtl of helpData;let S = index" class="home-list">
                                <div class="head">
								<h4 >
                                    {{dtl?.qus}}</h4>
                                </div>
                                    
								<!-- <h4  id="showiconclass{{S}}" (click)="gotoaddcall(S)" class="hideiconclass clearhide">
                                    {{dtl?.qus}}</h4> -->
                                    <div class="sc-right with-progress">
										<div class="scr-inner">
											<i  id="showiconclass{{S}}" (click)="gotoaddcall(S)" class="fas fa-caret-down clearshow">
                                            </i>
                                        
											<i  id="hideiconclass{{S}}" (click)="gotoremovecall(S)" class="fas fa-caret-up hideiconclass clearhide"
											></i>  
                                            </div>
                                            </div>
								
								<div class="row">
                                    <div class="ciw-single"  >
                                        <div class="sc-bottom sc-bottom-report clearclass" id="addclass{{S}}">
                                            <div class="video-block">
                                                <iframe width="400" height="250" src="https://www.youtube.com/embed/zH4s1H2pHyM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                           
                                        <div class="details">
                                            <h4>
                                                Title of the video link
                                            </h4>
                                            <span>{{dtl.ans}}</span>
                                        </div> </div></div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
</main>